import {objArrMapObj} from "@/untils/otherEvent"

/** 活谱-评论审核-table */
export const coinBookCommentListTableColumns = [
    { title: "用户信息", align: "center", scopedSlots: { customRender: "itemUserInfoSlot" }, width: 100 },
    { title: "评论类型/状态", align: "center", scopedSlots: { customRender: "itemStatusOrTypeSlot" }, width: 200 },
    { title: "评论内容", align: "center", scopedSlots: {customRender: 'itemCommentDetailSlot'}, width: 400 },
    { title: "提交时间", align: "center", dataIndex: 'createTime', width: 100},
    { title: "操作人", align: "center", dataIndex: 'sysOper', width: 100 },
    { title: "操作", align: "center", scopedSlots: { customRender: "itemActionSlot" } },
]


/** 评论类型 */
export const COMMENT_TYPE_LIST = [
    {name: '拓片', value: 6},
    {name: '实物', value: 8},
]

/** 评论类型对应的样式 */
export const COMMENT_TYPE_CLASS_TYPE = {
    6: 'color-green',
    8: 'color-blue'
}

/** 评论类型 */
export const COMMENT_STATUS_TYPE_LIST = [
    {name: '审核不通过', value: -1},
    {name: '审核中', value: 0},
    {name: '审核通过', value: 1},
]

/** 评论类型对应的样式 */
export const COMMENT_STATUS_TYPE_CLASS_TYPE = new Map([
    [-1, 'color-red'],
    [0, 'color-gray'],
    [1, 'color-green'],
])

export const commentTypeListMapText = (value) => {
    return objArrMapObj('value', 'name', COMMENT_TYPE_LIST)[value]
}
export const commentStatusTypeListMapText = (value) => {
    return objArrMapObj('value', 'name', COMMENT_STATUS_TYPE_LIST)[value]
}

