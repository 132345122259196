import instance from "@/axios"

/** 获取活谱-评论审核列表数据 */
export const getListByReview = async (params) => {
    return instance({
        url: '/dq_admin/tukuVersionComment/list',
        method: 'GET',
        params: params
    })
}

/** 删除-评论审核列表数据 */
export const deleteListByReview = async (params) => {
    return instance({
        url: '/dq_admin/tukuVersionComment/commentDelete',
        method: 'POST',
        data: params
    })
}

/** 同步评论图片到版别数据 */
export const againCommentDataToVersionData = async (params) => {
    return instance({
        url: '/dq_admin/tukuVersionComment/useCommentImage',
        method: 'POST',
        data: params
    })
}

/** 评论审核 */
export const commentConfirmStatus = async (params) => {
    return instance({
        url: '/dq_admin/tukuVersionComment/commentAudit',
        method: 'POST',
        data: params
    })
}