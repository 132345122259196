<template>
  <div class="content">
    <div class="top">
      <SearchBigOrSmallCoinByVersion
          :params="params"
      >
        <template #searchParamsBefore>
          <a-select
              :dropdownMatchSelectWidth="false"
              allowClear
              class="w-150 ml-5"
              v-model="params.type"
              placeholder="请选择评论的类型"
          >
            <a-select-option
                v-for="item of commentTypeList"
                :key="item.value"
                :value="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
          <a-select
              :dropdownMatchSelectWidth="false"
              allowClear
              class="w-150 ml-5"
              v-model="params.state"
              placeholder="请选择审核状态"
          >
            <a-select-option
                v-for="item of commentStatusList"
                :key="item.value"
                :value="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
        </template>
        <template #searchParamsAfter>
          <a-button
              class="ml-10"
              type="primary"
              @click="handleSearch"
          >搜索</a-button>
        </template>
      </SearchBigOrSmallCoinByVersion>
    </div>
    <div class="table-content">
      <ReviewTable
          :list="data"
          :pagination="pagination"
          @changePage="handleChangePage"
          @success="getList"
      />
    </div>
  </div>
</template>
<script>
import SearchBigOrSmallCoinByVersion from "@/components/SearchBigOrSmallCoinByVersion/index.vue"
import ReviewTable from "@/views/cmsPage/coinBookManage/reviewManage/_components/ReviewTable/index.vue"
import {getListByReview} from "@/views/cmsPage/coinBookManage/reviewManage/_apis"
import {COMMENT_STATUS_TYPE_LIST, COMMENT_TYPE_LIST} from "@/views/cmsPage/coinBookManage/reviewManage/_data"
import untils from "@/untils"
export default {
  components: { ReviewTable, SearchBigOrSmallCoinByVersion},
  data() {
    return {
      untils,
      commentTypeList: COMMENT_TYPE_LIST,
      commentStatusList: COMMENT_STATUS_TYPE_LIST,
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        pageNum: 1,
        pageSize: 20,
        type: undefined,
        state: undefined,
        coinKindId: undefined,
        coinKindItemId: undefined,
        coinKindItemVersionId: undefined,
      },
    }
  },
  created() {
    this.getList()
  },
  methods: {
    /** 翻页 */
    handleChangePage(page) {
      const { current } = page;
      this.$set(this.pagination, "current", current)
      this.$set(this.params, "pageNum", current)
      this.getList()
    },
    /** 搜索 */
    handleSearch() {
      this.params.pageNum = 1
      this.pagination.current = 1
      this.getList()
    },
    /** 获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await getListByReview(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.data = res.data.records
      this.pagination.total = res.data.total
    }
  }
}
</script>
<style scoped lang="scss">
.pagination {
  text-align: right;
  margin-top: 10px;
}
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
</style>