var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c(
      "div",
      { staticClass: "top" },
      [
        _c("SearchBigOrSmallCoinByVersion", {
          attrs: { params: _vm.params },
          scopedSlots: _vm._u([
            {
              key: "searchParamsBefore",
              fn: function() {
                return [
                  _c(
                    "a-select",
                    {
                      staticClass: "w-150 ml-5",
                      attrs: {
                        dropdownMatchSelectWidth: false,
                        allowClear: "",
                        placeholder: "请选择评论的类型"
                      },
                      model: {
                        value: _vm.params.type,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "type", $$v)
                        },
                        expression: "params.type"
                      }
                    },
                    _vm._l(_vm.commentTypeList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.value, attrs: { value: item.value } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  ),
                  _c(
                    "a-select",
                    {
                      staticClass: "w-150 ml-5",
                      attrs: {
                        dropdownMatchSelectWidth: false,
                        allowClear: "",
                        placeholder: "请选择审核状态"
                      },
                      model: {
                        value: _vm.params.state,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "state", $$v)
                        },
                        expression: "params.state"
                      }
                    },
                    _vm._l(_vm.commentStatusList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.value, attrs: { value: item.value } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "searchParamsAfter",
              fn: function() {
                return [
                  _c(
                    "a-button",
                    {
                      staticClass: "ml-10",
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSearch }
                    },
                    [_vm._v("搜索")]
                  )
                ]
              },
              proxy: true
            }
          ])
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "table-content" },
      [
        _c("ReviewTable", {
          attrs: { list: _vm.data, pagination: _vm.pagination },
          on: { changePage: _vm.handleChangePage, success: _vm.getList }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }