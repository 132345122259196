<template>
  <div>
    <a-table
        :columns="tableList"
        :data-source="list"
        :pagination="pagination"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--  用户信息    -->
      <div slot="itemUserInfoSlot" slot-scope="row">
        <div>{{ row.userName }} <span class="color-green">【{{ row.userId}}】</span></div>
        <div class="color-gray mt-10">点赞数：<span class="color-black font-weight-bold">{{ row.likeCount }}</span></div>
      </div>
<!--  状态，类型    -->
      <div slot="itemStatusOrTypeSlot" slot-scope="row">
        <div :class="commentTypeClassType[row.type]">{{ computedCommentTypeListMapText(row.type) }}</div>
        <div class="mt-10" :class="commentStatusClassType.get(row.state)">{{ computedCommentStatusTypeListMapText(row.state) }}</div>
      </div>
<!--  评论内容    -->
      <div slot="itemCommentDetailSlot" slot-scope="row">
        <div class="font-weight-bold font-size-14">评论的活谱版别：<span class="color-dq-blue">{{ row.kindItemVersionFullName }}</span></div>
        <div v-if="8 === Number(row.type)">
          <div class="color-gray mt-10">评论内容：<span class="color-black">{{ row.description }}</span></div>
          <div class="flex-center-center img-list-comment">
            <img
                :src="row.tukuCommentRealImage"
                alt=""
                @click="handlePreviewImg([row.tukuCommentRealImage, row.tukuCommentRealBackImage], 0)"
            >
            <img
                :src="row.tukuCommentRealBackImage"
                alt=""
                @click="handlePreviewImg([row.tukuCommentRealImage, row.tukuCommentRealBackImage], 1)"
            >
          </div>
        </div>
        <div v-if="6 === Number(row.type)">
          <div class="color-gray mt-10">评论内容：<span class="color-black">{{ row.commentContent }}</span></div>
        </div>
      </div>
      <!-- 图片列表     -->
      <div slot="itemImgListSlot" slot-scope="row">
        <img
            v-for="(item, index) in row.imgList"
            :key="index"
            :src="item"
            alt=""
            @click="handlePreviewImg(row.imgList, index)"
        >
      </div>
      <!--   操作   -->
      <div slot="itemActionSlot" slot-scope="row">
       <div class="flex-center-center" v-if="row.state === 0">
         <a-popconfirm
             title="确定通过此评论?"
             ok-text="Yes"
             cancel-text="No"
             @confirm="handleConfirmOrRejectComment(row, 1)"
         >
           <a-button class="" type="primary">通过</a-button>
         </a-popconfirm>

         <a-popconfirm
             title="确定将此评论驳回?"
             ok-text="Yes"
             cancel-text="No"
             @confirm="handleConfirmOrRejectComment(row, -1)"
         >
           <a-button class="ml-20" type="danger">驳回</a-button>
         </a-popconfirm>
       </div>
        <a-popconfirm
            title="确定同步?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleAgainToVersionByUserComment(row)"
        >
          <a-button v-if="row.type === '8'" class="mt-10" type="primary">用户图片同步到版别</a-button>
        </a-popconfirm>

        <a-popconfirm
            title="是否确认删除?"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleDeleteImgStore(row)"
        >
          <a-button class="mt-10" type="danger">删除</a-button>
        </a-popconfirm>
      </div>

    </a-table>
  </div>
</template>
<script>
import {deleteListByCoinBook} from "@/views/cmsPage/coinBookManage/ImgStoreManage/_apis"
import {
  coinBookCommentListTableColumns, COMMENT_STATUS_TYPE_CLASS_TYPE,
  COMMENT_STATUS_TYPE_LIST, COMMENT_TYPE_CLASS_TYPE,
  COMMENT_TYPE_LIST, commentStatusTypeListMapText, commentTypeListMapText
} from "@/views/cmsPage/coinBookManage/reviewManage/_data"
import {againCommentDataToVersionData, commentConfirmStatus} from "@/views/cmsPage/coinBookManage/reviewManage/_apis"

export default {
  props: ['list', 'pagination', "imgTypeList"],
  components: {},
  data() {
    return {
      tableList: coinBookCommentListTableColumns,
      commentTypeList: COMMENT_TYPE_LIST,
      commentTypeClassType: COMMENT_TYPE_CLASS_TYPE,
      commentStatusList: COMMENT_STATUS_TYPE_LIST,
      commentStatusClassType: COMMENT_STATUS_TYPE_CLASS_TYPE,
    }
  },
  computed: {
    computedCommentTypeListMapText: () => {
      return (value) => commentTypeListMapText(value)
    },
    computedCommentStatusTypeListMapText: () => {
      return (value) => commentStatusTypeListMapText(value)
    }
  },
  methods: {
    /** 同步到版别，将用户的图片 */
    async handleAgainToVersionByUserComment(row) {
      const res = await againCommentDataToVersionData({id: row.id})
      if (res.status !== '200') return
      this.$message.info(res.message || '成功')
      this.handleSuccess()
    },
    /** 驳回或通过评论 */
    async  handleConfirmOrRejectComment(row, type) {
      const res = await commentConfirmStatus({id: row.id, state: type})
      if (res.status !== '200') return
      this.$message.info(res.message || '成功')
      this.handleSuccess()
    },
    /** 删除 */
    async handleDeleteImgStore(row) {
      const res = await deleteListByCoinBook({ id: row.id })
      if (res.status !== '200') return
      this.$message.info('删除成功')
      this.handleSuccess()
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
    handlePreviewImg(images, index) {
      const urls = images.map(el => {
        return {img_url: el}
      })
      this.$previewImg({
        list: urls,
        current: index,
        baseImgField: "img_url",
        showMute: false,
      });
    },
    handleSuccess() {
      this.$emit('success')
    }
  }
}
</script>
<style lang="scss" scoped>
.img-list-comment {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: 50px;
  }
}
</style>