<template>
  <div class="content">
    <div class="search-attr">
      <div style="width:100%;display: flex;justify-content: space-between;align-items: center">
        <div>
          <a-input
              allowClear
              v-model="searchParams.productName"
              size='small'
              style="width:150px;margin:5px;"
              placeholder='标题/商品ID/商品编号'
          ></a-input>
          <a-input
              allowClear
              v-model="searchParams.userName"
              size='small'
              style="width:160px;margin:5px;"
              placeholder='昵称/店铺名/userId'
          ></a-input>

          <a-input
              allowClear
              v-model="searchParams.productCode"
              size='small'
              style="width:110px;margin:5px;"
              placeholder='评级编号'
          ></a-input>

          <a-select
              allowClear
              v-model="searchParams.reviewStatus"
              size='small'
              style="width:100px;margin:5px;"
              placeholder='审核状态'
          >
            <a-select-option :value='0'>待审核</a-select-option>
            <a-select-option :value='8'>通过极品</a-select-option>
            <a-select-option :value='1'>通过精品</a-select-option>
            <a-select-option :value='2'>通过普品</a-select-option>
            <a-select-option :value='3'>通过差品</a-select-option>
            <a-select-option :value='-1'>驳回</a-select-option>
          </a-select>


          <a-select
              allowClear
              v-model="searchParams.onShelf"
              size='small'
              style="width:100px;margin:5px;"
              placeholder='上下架'
          >
            <a-select-option :value='1'>上架</a-select-option>
            <a-select-option :value='0'>下架</a-select-option>
          </a-select>

          <a-select
              allowClear
              v-model="searchParams.finishStatus"
              size='small'
              style="width:100px;margin:5px;"
              placeholder='拍卖状态'
          >
            <a-select-option :value='10'>拍卖中</a-select-option>
            <a-select-option :value='20'>截拍</a-select-option>
            <a-select-option :value='30'>流拍</a-select-option>
            <a-select-option :value='40'>违约</a-select-option>
            <a-select-option :value='100'>成交</a-select-option>
          </a-select>
          <a-select
              size="small"
              @search="searchBigCoinListData"
              v-model="searchParams.coinId"
              :filter-option="untils.filterOption"
              showSearch
              placeholder="请选择大币种"
              allowClear
              style="width:150px;margin:5px;"
          >
            <a-select-option
                v-for="item of bigCoinListData"
                :key="item.id"
                :value="item.id"
            >{{ item.coinKindName }}</a-select-option>
          </a-select>
          <a-range-picker
              :disabled="nullEndTimeCheck"
              :placeholder="['截拍开始日期','截拍结束日期']"
              size='small'
              style="width:200px;margin:5px;"
              @change="searchPickerChange"
          />

          <a-range-picker
              :placeholder="['审核开始日期','审核结束日期']"
              size='small'
              style="width:200px;margin:5px;"
              @change="searchReviewTimePickerChange"
          />

          <a-select
              allowClear
              v-model="searchParams.expectPrice"
              size='small'
              style="width:130px;margin:5px;"
              placeholder='心理价筛选'
          >
            <a-select-option value='0-200'>0-200</a-select-option>
            <a-select-option value='201-500'>201-500</a-select-option>
            <a-select-option value='501-1000'>501-1000</a-select-option>
            <a-select-option value='1000-max'>1000以上</a-select-option>
            <a-select-option value='5000-max'>5000以上</a-select-option>
            <a-select-option value='10000-max'>10000以上</a-select-option>
            <a-select-option value='20000-max'>20000以上</a-select-option>
            <a-select-option value='50000-max'>50000以上</a-select-option>
            <a-select-option value='100000-max'>100000以上</a-select-option>
          </a-select>

          <a-select
              allowClear
              v-model="searchParams.isMachine"
              size='small'
              style="width:100px;margin:5px;"
              placeholder='是否委托'
          >
            <a-select-option value='1'>是</a-select-option>
            <a-select-option value='0'>否</a-select-option>
          </a-select>
          <a-select
              allowClear
              v-model="searchParams.tagId"
              size='small'
              style="width:200px;margin:5px;"
              placeholder='选择拍品标签'
          >
            <a-select-option
                v-for="item in queryProductTagList"
                :key="item.id"
                :value="item.id"
            >{{ item.tagName }}</a-select-option>
          </a-select>
          <a-select
              allowClear
              v-model="searchParams.productRatingCompany"
              size='small'
              style="width:150px;margin:5px;"
              placeholder='评级公司'
          >
            <a-select-option
                v-for="item in ratingCompanyData"
                :key="item.id"
                :value='item.id'
            >{{ item.value }}</a-select-option>
          </a-select>
          <a-select
              allowClear
              v-model="searchParams.categoryReviewType"
              size='small'
              style="width:100px;margin:5px;"
              placeholder='审核类型'
          >
            <a-select-option :value='1'>古钱币</a-select-option>
            <a-select-option :value='2'>银元</a-select-option>
          </a-select>
          <a-select
              allowClear
              v-model="searchParams.dynastyType"
              size='small'
              style="width:100px;margin:5px;"
              placeholder='朝代'
          >
            <a-select-option
                v-for="item in dynastyTypeData"
                :key="item.id"
                :value='item.id'
            >{{ item.value }}</a-select-option>
          </a-select>
          <a-select
              allowClear
              v-model="searchParams.periodId"
              :dropdownMatchSelectWidth="false"
              :filter-option="utils.filterOption"
              showSearch
              size="small"
              style="width: 200px; margin-left: 5px;"
              class="header-item"
              @search="getSquareList"
              placeholder='请输入场次名称进行查找'
          >
            <a-select-option
                v-for="item in squareList"
                :key="item.value"
                :value='item.value'>{{ item.name + `【${item.num}】` }}
            </a-select-option>
          </a-select>
          <a-select
              allowClear
              v-model="searchParams.ratingServiceStatus"
              :dropdownMatchSelectWidth="false"
              :filter-option="utils.filterOption"
              size="small"
              style="width: 200px; margin-left: 5px;"
              class="header-item"
              placeholder='选择是否包评级'
          >
            <a-select-option :value="1">包评级</a-select-option>
            <a-select-option :value="0">不包评级</a-select-option>
          </a-select>
          <a-button
              @click="search"
              size='small'
              type="primary"
              style="margin-left:10px;"
          >搜索</a-button>
          <a-checkbox :checked='!!searchParams.deleteFlag' @change="changeDeleteCheck" style="margin-left: 20px;">
            是否已删除
          </a-checkbox>
          <a-checkbox
              :disabled="!(searchParams.queryStartEndTime == '' || searchParams.queryStartEndTime == undefined || searchParams.queryStartEndTime == null)"
              :checked='nullEndTimeCheck' @change="changeNullEndTimeCheck" style="margin-left: 20px;">
            未设置节拍时间
          </a-checkbox>
          <span class="change-box">
            <a-button
                size="small"
                style="margin-right: 5px"
                @click="handleAllConfirm"
                :disabled="selectedRowKeys.length < 1"
                type="danger"
            >批量推送到拍场</a-button>
          </span>
        </div>
      </div>

    </div>
    <div class="table-content">
      <a-table
          @change="changePage"
          :pagination='pagination'
          :loading='loading'
          :columns="columns"
          :data-source="data"
          :rowKey="(record,n)=>n"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange
          }"
      >
        <div
            slot="idSlot"
            :style="{'color': row.deleteFlag === 1 ? 'gray' : ''}"
            slot-scope="row"
        >
          <div style="max-width:150px;">
            <div v-if="row.periodId">
              <a-popconfirm
                  title="确定删除推送的拍场？"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="handleDeletePushSquare(row)"
              >
                <a-button
                    size="small"
                    type="danger"
                    :disabled="row.deleteFlag === 1"
                    style='margin-top: 15px;'
                >删除推送的拍场</a-button>
              </a-popconfirm>
            </div>
            <a-popover
                title="商品编号"
                trigger="hover"
            >
              <template slot="content">
                <p>{{row.productNo}}</p>
              </template>

              <a
                  style='color:blue'
                  :style="{'color': row.deleteFlag === 1 ? 'gray' : ''}"
                  @click="copy(row.productNo)"
                  type="link"
              >
                {{row.id}}
              </a>
              <div v-if="row.simProductIdList"
                   style="
                   margin-top: 10px;">
                <div>相似拍品：</div>
                <div
                    style="color: red;cursor: pointer"
                    v-for="(item, index) in row.simProductIdList" :key="index"
                    @click="handleGoToOther(item.productId)"
                >{{ item.productId }} 相似度:{{ item.distance }}</div>
              </div>

            </a-popover>
            <div v-if="row.reviewAdmin">审核人:{{ row.reviewAdmin }}</div>
          </div>
          <div style="margin-top: 10px">
            <div >{{ row.coinKindName }}</div>
            <div v-if="row.shellType">
              <span
                  :class="row.shellType === 'luobi' ? 'color-blue' : 'color-green'"
              >({{ row.shellType === 'luobi' ? '裸币' : '盒子币' }})</span>
            </div>
            <div>
              <a-button
                  :disabled="row.deleteFlag === 1"
                  @click="handleShowChangeBigCoinModel(row)"
                  size="small"
              >更改大币种</a-button>
            </div>
          </div>
        </div>

        <div
            slot="images"
            slot-scope="row"
            class="height-column-sp-bt-center"
        >
          <div class="images-box">
            <div
                v-for="(item, index) in row.coverUrls"
                :key="index"
                v-lazy-container="{ selector: 'img' }"
            >
              <img
                  @click="handlePreviewImg(row.coverUrls, row, index)"
                  style='width:100px;height:100px;'
                  :class="row.deleteFlag === 1 ? 'delete-auction' : ''"
                  :data-src="item + '/resize,w_200'"
              />
              <div class="img-source-popup">
                <a-button size="small" type="primary" @click="handleShowImgMoreInfo(item, row.coverUrls[index])">类型检测</a-button>
              </div>
            </div>
          </div>
        </div>
        <!-- 裁剪图片 -->
        <div
            slot="cutImages"
            slot-scope="row"
            class="height-column-sp-bt-center"
        >
          <div
              v-if="row.cutCoverUrl"
              style='max-width:100px;max-height:100px;display:flex;flex-direction: column;justify-content:center;align-items:center;'
          >
            <img
                @click="previewImg(row.cutCoverUrls)"
                style='max-width:100px;max-height:100px;margin-bottom: 6px'
                :class="row.deleteFlag === 1 ? 'delete-auction' : ''"
                :src='row.cutCoverUrl || ""'
            />
          </div>
          <div style="margin-top: 20px;width:100%;display: flex;justify-content: center;align-items: center">
            <a-button
                @click="cutAndRatingData(row)"
                size="small"
                :disabled="row.deleteFlag === 1"
            >裁剪-评级</a-button>
          </div>
          <div style="margin-top: 10px;width:100%;display: flex;justify-content: center;align-items: center">
            <a-button
                @click="showVideoModel(row)"
                size="small"
                :disabled="row.deleteFlag === 1 || !row.productExtendInfo"
            >查看视频</a-button>
          </div>
        </div>

        <!-- 官网图片 -->
        <div
            slot="officialImages"
            slot-scope="row"
            class="height-column-sp-bt-center flex-colum-start"
        >
          <div
              style='max-width:100px;max-height:100px;display:flex;flex-direction: column;justify-content:center;align-items:center;'>
            <div v-if="row.reviewTipRemark" style="margin-bottom: 5px;"> <a-tag color="red">{{ row.reviewTipRemark }}</a-tag></div>
            <div v-if="row.officialCoverUrl">
              <img
                  @click="previewImg(row.officialCoverUrls)"
                  style='max-width:100px;max-height:100px;margin-bottom: 6px'
                  :class="row.deleteFlag === 1 ? 'delete-auction' : ''"
                  :src='row.officialCoverUrl || ""'
              />

              <a-popconfirm
                  title="删除官网图,同时也会删除评级编号,需重新录入编号"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="deleteOfficialCoverImage(row)"
              >
                <a-button
                    v-if="row.officialCoverUrls.length > 0"
                    size="small"
                    :disabled="row.deleteFlag === 1"
                    style='margin-top: 15px;'
                >删除</a-button>
              </a-popconfirm>
            </div>
          </div>

        </div>


        <!-- titleSlot -->
        <div
            slot="titleSlot"
            slot-scope="row"
            class="height-column-sp-bt-center"
        >
          <div>
            <div>
              【{{ submitSourceType[row.submitSource] }}】
            </div>
            <div>
              <a-tag
                  v-if="row.ratingServiceStatus"
                  :color="row.deleteFlag === 1 ? 'gray' : 'blue'"
              >放心购拍品</a-tag>
            </div>
            <p
                @dblclick="handleDoubleClick(row.id, row.productName)"
                :style="{'color': row.deleteFlag === 1 ? 'gray' : ''}"
                v-if="!row.showDoubleInput"
            >{{row.productName}}</p>
            <div
                v-else-if="row.deleteFlag === 0"
                style="width: 100%;display: flex;justify-content: center;align-items: center"
            >
              <a-textarea
                  type="text"
                  style="width: 200px;"
                  v-model="selectProductName"
                  :auto-size="{ minRows: 4, maxRows: 6 }"
                  placeholder="填写拍品名称"
              ></a-textarea>
              <div style="margin-left: 10px;display: flex;flex-direction: column;justify-content: center;align-items: center">
                <a-icon @click="handleConfirm(row)" type="check-circle" style="font-size: 24px;color: blue;cursor: pointer;"/>
                <a-icon @click="handleClose(row)" type="close-circle" style="font-size: 24px;color: red;margin-top: 20px;cursor: pointer"/>
              </div>
            </div>

            <div class="auction-desc-text" v-if="row.context">
              <span>『拍品介绍』</span>{{ row.context }}
            </div>

            <p
                v-if="row.expectPrice"
                style="color:rgb(217,13,38);font-weight:bold;"
                :style="{'color': row.deleteFlag === 1 ? 'gray' : 'rgb(217,13,38)'}"
            >心理价:{{row.expectPrice}}
              <span v-if="row.incrPrice">/{{ row.incrPrice }}</span>
            </p>
          </div>
          <div style="display: flex;flex-direction:column;justify-content: center;align-items: center">
            <div>尺寸：{{ row.productSize }}</div>
            <div v-if="row.machinePrice>0"> 委托价：{{row.machinePrice}}</div>
            <div style="margin-top: 20px;width:100%;display: flex;justify-content: center;align-items: center">
              <a-popconfirm
                  title="确认为极品吗?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="reviewAuctionGood(row.id,'bestsuccess',null)"
              >
                <a-button
                    type="danger"
                    size="small"
                    :disabled="row.deleteFlag === 1"
                >极品</a-button>
              </a-popconfirm>

              <a-popconfirm
                  title="确认为精品吗?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="reviewAuctionGood(row.id,'goodsuccess',null)"
              >
                <a-button
                    type="primary"
                    size="small"
                    style='margin-left:5px'
                    :disabled="row.deleteFlag === 1"
                >精品</a-button>
              </a-popconfirm>

              <a-popconfirm
                  title="确认为普通吗?"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="reviewAuctionGood(row.id,'normalsuccess',null)"
              >
                <a-button
                    size="small"
                    :disabled="row.deleteFlag === 1"
                    style='margin-left:5px'
                >普通</a-button>
              </a-popconfirm>

              <a-popconfirm
                  title="确认为差品吗?"
                  ok-text="Yes"
                  cancel-text="No"
                  :disabled="row.deleteFlag === 1"
                  @confirm="reviewAuctionGood(row.id,'rubbishsuccess',null)"
              >
                <a-button
                    size="small"
                    type="danger"
                    :disabled="row.deleteFlag === 1"
                    style='margin-left:5px'
                >差品</a-button>
              </a-popconfirm>
            </div>
            <div style="margin-top: 20px;width:100%;display: flex;justify-content: center;align-items: center">
              <a-button
                  v-if="row.reviewStatus === 0 || row.reviewStatus === 1 || row.reviewStatus === 2 || row.reviewStatus === 3 || row.reviewStatus === 8"
                  @click="openRefuseModal(row)"
                  size="small"
                  :disabled="row.deleteFlag === 1"
                  type="danger"
              >驳回</a-button>

              <a-button
                  :disabled="row.deleteFlag === 1"
                  size="small"
                  type="primary"
                  @click="openModal(row,1)"
                  style='margin-left:5px'
              >编辑</a-button>
            </div>
            <div style="margin-top: 20px;width:100%;display: flex;justify-content: center;align-items: center">
              <a-popconfirm
                  title="立即截拍？"
                  ok-text="Yes"
                  cancel-text="No"
                  @confirm="handleAuctionOver(row)"
              >
                <a-button
                    v-if="localEnv !== 'production'"
                  size="small"
                  >立即截拍</a-button>
              </a-popconfirm>
            </div>
          </div>
        </div>

        <!--    绑定 标签    -->
        <div slot="itemTagSlot" slot-scope="row">
          <div style="margin-bottom: 10px">
            <a-popconfirm
                :title="row.homeType === 8 ? '确定取消标王？' : '确定标王？'"
                ok-text="Yes"
                cancel-text="No"
                @confirm="handleConfirmKing(row)"
            >
              <a-button
                  size="small"
                  :type="row.homeType === 8 ? 'danger' : ''"
              >{{ row.homeType === 8 ? '已标王' : '标王' }}</a-button>
            </a-popconfirm>
            <a-tooltip placement="top">
              <template slot="title">
                <span>修改标王截拍时间</span>
              </template>
              <a-button
                  :disabled="row.homeType !== 8"
                  class="ml-10"
                  size="small"
                  icon="edit"
                  @click="handleChangeKingTime(row)"
              ></a-button>
            </a-tooltip>
          </div>
          <div
              class="tag-up-home"
              :class="row.homeType ? 'cur-tag-item' : ''"
              @click="handleProductUpHome(row)"
          >{{ row.homeType ? '已推到首页' : '推首页' }}</div>
          <div class="tag-list" v-if="productTagList && productTagList.length">
            <div
                class="tag-item"
                v-for="item in productTagList"
                :key="item.id"
                :class="productTagSelectedFlag(row.productTagsList, item) ? 'cur-tag-item' : ''"
                @click="handleEditorProductTag(row, item)"
            >
              {{ item.tagName }}
            </div>
          </div>
        </div>

        <div
            slot="descriptionSlot"
            slot-scope="row"
        >
          <div class="w-100-w">
            <div
                @click="copy(row.productRemarkVo.coinStateRemark)"
                :style="{'color': row.deleteFlag === 1 ? 'gray' : ''}"
            >
              平台定义瑕疵：{{ row.productRemarkVo.coinStateRemark || ' '}}
            </div>
            <div
                @click="copy(row.productRemarkVo.customRemark)"
                :style="{'color': row.deleteFlag === 1 ? 'gray' : ''}"
            >
              用户自定义瑕疵：
              <span class="color-red">{{ row.productRemarkVo.customRemark }}</span>
            </div>
            <div
                @click="copy(row.productRemarkVo.platformRemark)"
                :style="{'color': row.deleteFlag === 1 ? 'gray' : ''}"
            >
              平台点评：{{ row.productRemarkVo.platformRemark || ' '}}
            </div>
            <div
                @click="copy(row.productRemarkVo.ratingFlawCode)"
                :style="{'color': row.deleteFlag === 1 ? 'gray' : 'orange'}"
            >
              评级编码：{{ row.productRemarkVo.ratingFlawCode || ' '}}
            </div>
            <div
                @click="copy(row.productRemarkVo.ratingRemark)"
                :style="{'color': row.deleteFlag === 1 ? 'gray' : 'orange'}"
            >
              评级备注：{{ row.productRemarkVo.ratingRemark || ' '}}
            </div>
            <div>
              <a-button
                  size="small"
                  :disabled="row.deleteFlag === 1"
                  style='margin-left:5px'
                  @click="handleEditorProductRemark(row)"
              >编辑产品瑕疵</a-button>
            </div>
            <p
                style="color:rgb(217,13,38);font-weight:bold;margin-top: 10px;"
                :style="{'color': row.deleteFlag === 1 ? 'gray' : 'rgb(217,13,38)'}"
            >￥{{row.finalPrice}}</p>

          </div>
        </div>


        <div
            slot="statusSlot"
            slot-scope="row"
        >
          <div v-if="row.subDownStatus">拷贝来源：<span :class="copyStoreSourceClassType[row.subDownStatus]">{{ computedCopyStoreSourceTypeListMapText(row.subDownStatus) }}</span></div>
          <div style="max-width:150px;">
            <p
                v-if="row.reviewStatus===-1"
                style="color: red"
                :style="{'color': row.deleteFlag === 1 ? 'gray' : 'red'}"
            >已驳回
              <br>
              {{row.reviewDetail.reviewReason}}
            </p>
            <p
                v-if="row.reviewStatus===0"
                style="color: #1890ff"
                :style="{'color': row.deleteFlag === 1 ? 'gray' : '#1890ff'}"
            >待审核</p>
            <p
                v-if="row.reviewStatus===1"
                style="color: green"
                :style="{'color': row.deleteFlag === 1 ? 'gray' : 'green'}"
            >通过精品</p>
            <p
                v-if="row.reviewStatus===8"
                style="color: green"
                :style="{'color': row.deleteFlag === 1 ? 'gray' : 'orange'}"
            >通过极品</p>
            <p
                v-if="row.reviewStatus===2"
                style="color: blue"
                :style="{'color': row.deleteFlag === 1 ? 'gray' : 'blue'}"
            >通过普通</p>
            <p
                v-if="row.reviewStatus===3"
                style="color: red"
                :style="{'color': row.deleteFlag === 1 ? 'gray' : 'red'}"
            >通过差品</p>

            <br>
            <p
                v-if="row.finishStatusValue"
                style="color: black"
                :style="{'color': row.deleteFlag === 1 ? 'gray' : 'black'}"
            >{{row.finishStatusValue}}</p>
          </div>
        </div>

        <div slot="tags" slot-scope="row">
          <a-tag
              v-for="(tag,key) in row"
              :key="key"
              :color="row.deleteFlag === 1 ? 'gray' : 'blue'"
          >{{ tag }}</a-tag>
        </div>

        <div slot="userName" slot-scope="row" >
          <span
              v-if="row.isGrayUser == 1"
              style='color:#1890ff'
              :style="{'color': row.deleteFlag === 1 ? 'gray' : '#1890ff'}"
              @click="userIdSearch(row.userId)"
              type="link"
          >
            {{ row.userName }}

          </span>
          <span
              v-if="row.isGrayUser == 0"
              style="color: #1890ff"
              :style="{'color': row.deleteFlag === 1 ? 'gray' : '#1890ff'}"
              @click="userIdSearch(row.userId)"
              type="link"
          >
            {{ row.userName }}
          </span>
        </div>

        <div slot="expandSlot" slot-scope="row" :style="{'color': row.deleteFlag === 1 ? 'gray' : ''}">
          <div>
            {{row.productRatingCompanyName || "无"}}
            {{row.productCode}}
            <p></p>
            <div>出价人数：{{row.offerPriceNum || "0"}}</div>
            <div>围观人数：{{row.watchNum}}</div>
            <div>曝光次数：{{row.exposeNum}}</div>
            <div style="margin-top: 20px;width:100%;display: flex;justify-content: center;align-items: center">
              <a-button
                  size="small"
                  @click="openPriceModal(row.id)"
                  style='margin-right:5px'
              >出价记录</a-button>
            </div>
          </div>
        </div>
        <div slot-scope="row" slot="endTime" :style="{'color': row.deleteFlag === 1 ? 'gray' : ''}">
          {{ row.endTime }}
        </div>
        <div slot-scope="row" slot="createTime" :style="{'color': row.deleteFlag === 1 ? 'gray' : ''}">
          {{ row.createTime }}
        </div>
      </a-table>
    </div>

    <a-modal
        :width='1000'
        v-model="visible"
        title="拍品编辑"
        ok-text="确认"
        cancel-text="取消"
        @cancel='resetForms'
        @ok="hideModal"
    >
      <a-form-model
          ref="ruleForm"
          :model="forms"
          :rules="rules"
          v-bind="layout"
      >
        <a-form-model-item
            label="商品名称"
            prop="productName"
        >
          <a-textarea
              auto-size
              size='small'
              placeholder='请输入'
              v-model="forms.productName"
          ></a-textarea>
        </a-form-model-item>
        <a-form-model-item
            label="拍品介绍"
            prop="productName"
        >
          <a-textarea
              auto-size
              size='small'
              placeholder='请输入'
              v-model="forms.context"
          ></a-textarea>
        </a-form-model-item>

        <a-form-model-item label="原图/展示图">
          <DraggableImg
              v-if="fileListYuan && fileListYuan.length"
              :list="fileListYuan"
              :postParams="{businessType: 'product'}"
              :maxLength="12"
              @dragImg="handleDragYuanImg($event)"
          />
        </a-form-model-item>
        <a-form-model-item label="封面图/裁剪图">
          <DraggableImg
              v-if="fileList && fileList.length"
              :list="fileList"
              :postParams="{businessType: 'product'}"
              :isShowSwitchBtn="true"
              :isShowCroppingBtn="true"
              :maxLength="12"
              @dragImg="handleDragImg($event)"
          />
        </a-form-model-item>
        <a-form-model-item
            label="评级公司"
            prop="productRatingCompany"
        >
          <a-radio-group
              name="radioGroup"
              v-model="forms.productRatingCompany"
          >
            <a-radio :value="0">裸币</a-radio>
            <a-radio :value="2">公博</a-radio>
            <a-radio :value="10">保粹</a-radio>
            <a-radio :value="11">华夏</a-radio>
            <a-radio :value="14">轩德</a-radio>
            <a-radio :value="16">闻德</a-radio>
            <a-radio :value="18">园地评级</a-radio>
            <a-radio :value="1">PCGS</a-radio>
            <a-radio :value="3">NGC</a-radio>
          </a-radio-group>
        </a-form-model-item>

        <a-form-model-item
            label="证书编号"
            prop="productCode"
            v-show="forms.productRatingCompany != 0"
        >
          <a-input
              placeholder="证书编号"
              v-model="forms.productCode"
          />
        </a-form-model-item>

        <a-form-model-item
            label="商品尺寸"
            prop="productSize"
        >
          <a-input
              placeholder="商品尺寸"
              v-model="forms.productSize"
          />
        </a-form-model-item>


        <a-form-model-item
            label="价格"
            prop="finalPrice"
        >
          <a-input
              size='small'
              placeholder='请输入价格'
              v-model="forms.finalPrice"
              disabled
          ></a-input>
        </a-form-model-item>

        <a-form-model-item
            label="运费"
            prop="logisticsFee"
        >
          <a-input
              size='small'
              placeholder='请输入运费0元即为包邮'
              v-model="forms.logisticsFee"
              disabled
          ></a-input>
        </a-form-model-item>

        <a-form-model-item
            label="心理价"
            prop="expectPrice"
        >
          <a-select
              size='small'
              @change='handleChangeExpectPrice'
              v-model="forms.expectPrice"
              :filter-option="untils.filterOption"
              showSearch
              placeholder='请选择'
          >
            <a-select-option
                v-for="item of headerPriceListData.priceList"
                :key="item.name"
                :value='item.name'
            >{{item.name + ` 加价幅度：【${item.value}】`}}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
            label="加价幅度"
            prop="incrPrice"
        >
          <a-input
              size='small'
              type = 'number'
              disabled
              placeholder='加价幅度'
              v-model="forms.incrPrice"
          ></a-input>
        </a-form-model-item>

        <a-form-model-item
            label="委托价"
            prop="machinePrice"
        >
          <a-input
              size='small'
              type = 'number'
              placeholder='委托价'
              v-model="forms.machinePrice"
          ></a-input>
        </a-form-model-item>

        <a-form-model-item
            label="大币种板块"
            prop="coinSid"
        >
          <a-select
              size='small'
              @search='searchData'
              v-model="forms.coinSid"
              :filter-option="untils.filterOption"
              showSearch
              placeholder='请选择'
          >
            <a-select-option
                v-for="item of currency_big_list"
                :key="item.id"
                :value='item.sid'
            >{{item.coinKindName}}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item
            label="截拍时间"
            prop="start_time"
        >
          <a-date-picker
              show-time
              size='small'
              v-model="forms.endTime"
              :defaultValue="moment(new Date, 'YYYY-MM-DD')"
              placeholder='选择日期'
              @change="changeTime"
              disabled
          />
        </a-form-model-item>


      </a-form-model>
    </a-modal>

    <a-modal
        :visible="previewVisible"
        :footer="null"
        @cancel="handleCancel"
    >
      <img
          alt="example"
          style="width: 100%"
          :src="previewImage"
      />
    </a-modal>

    <a-modal
        centered
        :width='800'
        v-model="visiblePrice"
        title="出价记录"
        @cancel='cancelModal'
        :footer="null"
    >
      <div class="take-box">
        <a-table
            @change="changePageModal"
            :pagination='paginationModal'
            :loading='loadingModal'
            :columns='takeColumns'
            :data-source='messages'
            :rowKey="(record,n)=>n"
        >
          <div slot="itemUserNameSlot" slot-scope="row">
            <div class="flex-colum-start">
              <div class="color-black">{{  row.userName }} <span v-if="row.sellerTowStatus">【<span class="color-orange">{{ row.sellerTowStatus }}</span>】</span></div>
              <div class="w-100-w flex-center-center mt-10">
                <div class="color-green">【{{  row.userId }}】</div>
                <img class="vip-img" v-if="row.userLevel" :src="userVipIcon[row.userLevel]" />
              </div>
            </div>
          </div>
        </a-table>
      </div>
    </a-modal>

    <a-modal
        v-model="refuseModal"
        :width='700'
        title="驳回"
        ok-text="确认"
        cancel-text="取消"
        @cancel="closeRefuseModal"
        @ok="confirmRefuseModal"
    >
      <a-select
          v-if="!showSelectRefuseAuto"
          allowClear
          v-model="productRefuseType"
          style="width:600px;"
          placeholder='请选择驳回原因'
          @change="handleChangeSelectRefuseType"
      >
        <a-select-option
            v-for="item in productRefuseTypeList"
            :key="item.id"
            :value='item.id'
        >
          <span>{{ item.value }}</span>
          <a-icon v-if="item.icon" :type="item.icon" style="color: #2d8cf0;margin-left: 5px;"/>
        </a-select-option>
      </a-select>
      <div v-else style="width: 100%;display: flex;justify-content: flex-start;align-items: center">
        <a-input
            allowClear
            v-model="showSelectRefuseText"
            style="width: 400px"
            placeholder='输入反驳原因'
        ></a-input>
        <a-tooltip placement="top">
          <template slot="title">
            <span>关闭自定义输入驳回原因</span>
          </template>
          <a-icon
              @click="handleCloseAuto"
              type="close-circle"
              style="margin-left: 20px;font-size: 24px;color: red;cursor: pointer"
          />
        </a-tooltip>
      </div>
    </a-modal>
    <!--  人工修改大币种  -->
    <ModalChangeBigCoin
        ref="change-coin"
        @change="handleChangeSuccess"
    />
    <ShowVideo ref="show-video"/>
    <ChangeKingTagPopup ref="changeKingTagPopupEl" @successEdit="getData"/>
    <PushSquarePopup ref="pushSquarePopupEl" @successEdit="getData"/>
    <EditorProductRemarkPopup ref="editorProductRemarkPopupEl" @editorSuccess="handleEditorSuccess($event)"/>
<!--  钱币识别  -->
    <ShowImgScorePopup ref="showImgScorePopupEl" />
<!--  驳回提示  -->
    <RejectTipMessagePopup ref="rejectTipMessagePopupEl" @checkSuccess="handleCheckSuccess"/>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import DraggableImg from "@/components/DraggableImg";
import utils from '@/untils/index.js'
import {dynastyTypeData, productRefuseTypeList, ratingCompanyData} from '../../_data'
import ModalChangeBigCoin from "@/views/cmsPage/productManage/auctionAboutList/_components/ModalChangeBigCoin";
import ShowVideo from "@/views/cmsPage/productManage/auctionAboutList/_components/ShowVideo";
import ChangeKingTagPopup from "@/views/cmsPage/productManage/auctionAboutList/_components/ChangeKingTagPopup";
import PushSquarePopup from "@/views/cmsPage/productManage/auctionAboutList/_components/PushSquarePopup"
import untils from "@/untils/index.js"
import {getLocalEnv} from "@/untils/otherEvent"
import {
  COPY_STORE_SOURCE_ClASS_TYPE,
  copyStoreSourceTypeListMapText, getAuctionHeartPriceListEvent, overwriteModifiedAuctionData,
  submitSourceType
} from "@/views/cmsPage/productManage/auctionAboutList/_services"
import EditorProductRemarkPopup
  from "@/views/cmsPage/productManage/_components/EditorProductRemarkPopup/index.vue"
import ShowImgScorePopup from "@/components/ShowImgScorePopup/index.vue"
import RejectTipMessagePopup
  from "@/views/cmsPage/productManage/auctionAboutList/_components/RejectTipMessagePopup/index.vue"
import {getAuctionLastTimeCheckStatusApi} from "@/views/cmsPage/productManage/auctionAboutList/_apis"
import {user_vip_level_icon} from "@/views/cmsPage/dqPlatform/_data"
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default {
  data() {
    return {
      utils,
      userVipIcon: user_vip_level_icon,
      copyStoreSourceClassType: COPY_STORE_SOURCE_ClASS_TYPE,
      localEnv: getLocalEnv(),
      isloadingStatus: false,
      dynastyTypeData: dynastyTypeData,
      ratingCompanyData: ratingCompanyData,
      productRefuseType: undefined,
      productRefuseTypeList: productRefuseTypeList,
      detail: "",
      columns: [
        { title: "ID", scopedSlots: { customRender: "idSlot" }, align: 'center', width: 100 },
        { title: "发拍人", scopedSlots: { customRender: "userName" }, align: 'center' },
        { title: "原图", scopedSlots: { customRender: "images" }, align: 'center', width: 400 },
        { title: "展示图", scopedSlots: { customRender: "cutImages" }, align: 'center' },
        { title: "官网图", scopedSlots: { customRender: "officialImages" }, align: 'center' },
        { title: "标签", scopedSlots: { customRender: "itemTagSlot" }, align: 'center', width: 240 },
        // { title: "拍品介绍", dataIndex: 'productDescription', align: 'center', width: 240 },
        { title: "标题", scopedSlots: { customRender: "titleSlot" }, align: 'center', width: 260 },
        { title: "描述", scopedSlots: { customRender: "descriptionSlot" }, width: 180, align: 'center' },
        { title: "状态", scopedSlots: { customRender: "statusSlot" }, align: 'center', width: 200},
        { title: "扩展", scopedSlots: { customRender: "expandSlot" }, align: 'center' },
        { title: "截拍时间", scopedSlots: { customRender: "endTime" }, align: 'center' },
        { title: "发布时间", scopedSlots: { customRender: "createTime" }, align: 'center' },
      ],
      submitSourceType,
      data: [],
      times: [],
      messages: [],
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      forms: {},
      visiblePrice: false,
      refuseModal: false,
      productRefuseInput: "宝贝违反平台规则强制下架",
      selectProductId: null,
      users: [],
      fileList: [],
      fileListYuan: [],
      rules: {
        userId: [{ required: true, message: "请选择用户", trigger: "change" }],
        finalPrice: [
          { required: true, message: "请输入价格", trigger: "blur" },
        ],
        coinSid: [
          { required: true, message: "请选择大币种版别", trigger: "change" },
        ],
        productName: [
          { required: true, message: "请输入内容", trigger: "blur" },
        ],
        logisticsFee: [
          { required: true, message: "请输入运费0元即为包邮", trigger: "blur" },
        ],
      },
      visible: false,
      params: { pageNum: 1, pageSize: 10 },
      IO: 0,
      tagCurrent: "",
      pagination: {
        showQuickJumper: true,
        total: 0,
        current: 1,
        showTotal: (total) => `共${total}条`,
      },
      loading: false,
      checkAll: false,
      indeterminate: false,
      searchTime: [],
      currency_big_list: [],
      searchParams: {
        userName: "",
        content: "",
        startTime: "",
        endTime: "",
        coinKindName: "",
        productName: undefined,
        queryStartEndTime: '',
        queryEndTime: '',
        deleteFlag: false,
        productRatingCompany: undefined,
        categoryReviewType: undefined,
        dynastyType: undefined,
        notEndTimeStatus: 0,
        ratingServiceStatus: undefined,
      },
      previewVisible: false,
      previewImage: "",
      inputValue: "",
      inputVisible: false,
      imgKey: "",
      kindList: [],
      paginationModal: {
        total: 0,
        current: 1,
        showTotal: (total) => `共${total}条`,
      },
      loadingModal: false,
      takeColumns: [
        { title: "昵称", scopedSlots: { customRender: 'itemUserNameSlot' }, width: 350 },
        { title: "出价", width: 100, dataIndex: "price" },
        {
          title: "时间",
          width: "200",
          align: "center",
          dataIndex: "auctionTime",
        },
      ],
      modalParams: {
        pageNum: 1,
        pageSize: 10,
        productId: undefined,
      },
      selectProductName: '',
      showSelectRefuseAuto:false,
      showSelectRefuseText: '',
      productTagList: [],
      queryProductTagList: [],
      selectedRowKeys: [],
      squareList: [],
      squareParams: {
        pageNum: 1,
        pageSize: 10,
        periodName: undefined
      },
      nullEndTimeCheck: false,
      bigCoinListData: [],
      untils,
      sourceType: 'default',
      headerPriceListData: {
        priceList: [],
        dataList: {}
      }
    };
  },
  components: {
    ShowVideo,
    DraggableImg,
    ModalChangeBigCoin,
    ChangeKingTagPopup,
    PushSquarePopup,
    EditorProductRemarkPopup,
    ShowImgScorePopup,
    RejectTipMessagePopup,
  },
  async created() {
    const { userName, productName, userId, sourceType } = this.$route.query;
    if (userName) {
      this.searchParams.userName = userName;
    }
    if (userId) {
      this.searchParams.userName = userId
    }
    if (productName) {
      this.searchParams.productName = productName;
    }
    if (sourceType) {
      this.sourceType = sourceType
    }
    await this.getData();
    await this.getTagList()
    await this.getUsers();
    // await this.getKinds();
    await this.getSquareList()
  },
  mounted() {
    addEventListener("paste", (e) => {
      if (this.visible) {
        this.untils.pasteImg(e, 1, this.paseImgs);
      }
    });
  },
  computed: {
    ...mapState(["ip"]),
    computedCopyStoreSourceTypeListMapText() {
      return (value) => copyStoreSourceTypeListMapText(value)
    },
    productTagSelectedFlag() {
      return (list, item) => {
        const tempArr = list.map(el => el.id || el.tagId)
        return !!tempArr.includes(item.id || item.tagId);
      }
    }
  },
  watch: {
    loading() {
      this.selectedRowKeys = [];
    },
  },
  methods: {
    moment,
    /** 二次驳回审核成功 */
    handleCheckSuccess() {
      this.refuseModal = false
      this.getData()
    },
    /** 展示拍品识别 */
    handleShowImgMoreInfo(cutImg, yuanImg) {
      this.$refs.showImgScorePopupEl.show(cutImg, yuanImg)
    },
    /** TODO 立即节拍 */
    async handleAuctionOver(row) {
      const res = await this.axios('/dq_admin/product/endProductCurrent', {
        params: {productId: row.id}
      })
      if (res.status !== '200') return
      await this.getData()
    },

    /** 搜索大币种 */
    searchBigCoinListData(inner) {
      this.axios("/dq_admin/kind/list", {
        params: { coinName: inner },
      }).then((res) => {
        if (res.status == "200") {
          const { records } = res.data;
          this.bigCoinListData = records;
        }
      });
    },
    /** 获取店铺列表 */
    async getSquareList(userName) {
      let params = JSON.parse(JSON.stringify(this.squareParams))
      params.periodName = userName
      const res = await this.axios('/dq_admin/productAuctionPeriod/list',{params: params})
      const {records } = res.data
      if (records && records.length) {
        this.squareList = records.map(el => {
          return {name: el.periodName, value: el.id, num: el.periodNum}
        })
      }
    },
    onSelectChange(keys, arr) {
      this.selectedRowKeys = keys;
    },
    /** 选择全部推送拍场 */
    handleAllConfirm() {
      let ids = []
      this.data.forEach((el, index) => {
        if (this.selectedRowKeys.includes(index)) {
          ids.push(el.id)
        }
      })
      this.$refs.pushSquarePopupEl.show(ids)
    },
    /** TODO 删除推送到拍场 */
    async handleDeletePushSquare(row) {
      const res = await this.axios.post(
          "/dq_admin/productAuctionPeriod/periodRemoveProduct",
          {
            periodId: row.periodId,
            productId: row.id
          }
      );
      if (res.status != 200) return;
      this.$message.success(res.message);
      this.data = await overwriteModifiedAuctionData(row.id, this.data)
    },
    /** 推送到拍场 */
    handleChangeKingTime(row) {
      if (!row.endTime) return this.$message.warn('当前拍品还未选择上拍不能修改截拍时间')
      this.$refs.changeKingTagPopupEl.show(row)
    },
    /** 标王 */
    async handleConfirmKing(row) {
      if (!row.endTime) return this.$message.warn('当前拍品没有节拍时间不能标记标王')
      if (row.reviewStatus === -1) return this.$message.warn('当前拍品被驳回不能标王')
      const res = await this.axios(
          "/dq_admin/product/perfectProductAction",
          {
            params: {
              productId: row.id
            },
          }
      );
      if (res.status != 200) return;
      this.$message.success(res.message);
      this.data = await overwriteModifiedAuctionData(row.id, this.data)
    },
    /** 查看视频 */
    showVideoModel(row) {
      this.$refs["show-video"].show(row)
    },
    /** 推荐到首页 */
    async handleProductUpHome(row) {
      if (row.deleteFlag) return this.$message.warn('已经删除禁止编辑')
      const res = await this.axios(
          "/dq_admin/product/recommendProductHomeAction",
          {
            params: {
              productId: row.id
            },
          }
      );
      if (res && res.status != 200) return;
      this.$message.success(res.message);
      this.data = await overwriteModifiedAuctionData(row.id, this.data)
    },
    /** 编辑标签 */
    async handleEditorProductTag(row, item) {
      if (row.deleteFlag) return this.$message.warn('已经删除禁止编辑')
      const res = await this.axios.post(
          "/dq_admin/productTag/productAddOrRemoveTag",
          {
            productId: row.id,
            tagId: item.id
          }
      );
      if (res.status != 200) return;
      this.$message.success(res.message);
      this.data = await overwriteModifiedAuctionData(row.id, this.data)
    },
    /** 获取商品tag列表 */
    async getTagList() {
      const res = await this.axios("/dq_admin/productTag/list", {
        params: {
          pageNum: 1,
          pageSize: 100
        },
      });
      if (res.status != 200) return;
      this.productTagList = res.data.records;
      this.queryProductTagList = [{id: -200, tagName: '标王'}, {id: -100, tagName: '推首页'}].concat(this.productTagList)
    },
    /** 没有节拍时间 */
    changeNullEndTimeCheck(e) {
      this.nullEndTimeCheck = e.target.checked
      this.search()
    },
    /** 切换是否已经删除 */
    changeDeleteCheck(e) {
      this.searchParams.deleteFlag = e.target.checked
      this.search()
    },
    /** 原图展示 */
    handlePreviewImg(urls, row, index) {
      if (urls && urls.length > 0) {
        this.$previewImg({
          list: urls.reduce((pre, cur) => {
            const obj = { img_url: cur };
            pre = pre.concat(obj);
            return pre;
          }, []),
          current: index,
          baseImgField: "img_url",
          showMute: false,
          showBigCoinName: row.coinKindName,
          showImageByListId: row.id,
          showBigCoinStatus: true,
          showShellStatus: true
        });
      }
    },
    /** 改变大币种成功 */
    async handleChangeSuccess(id) {
      this.data = await overwriteModifiedAuctionData(id, this.data)
    },
    /** 展示改变大币种 */
    handleShowChangeBigCoinModel(row) {
      this.$refs['change-coin'].show(row.id)
    },
    /** 相似拍品 */
    handleGoToOther(item) {
      const origin = location.origin
      const url = `${origin}/#/cms/currencymannage/auctiongoods?productName=${item}`;
      window.open(url)
    },
    /** 关闭自定义输入驳回原因 */
    handleCloseAuto() {
      this.productRefuseType = undefined
      this.showSelectRefuseAuto = false
    },
    /** 切换驳回原因 */
    handleChangeSelectRefuseType(e) {
      if (e === 'auto') {
        this.showSelectRefuseAuto = true
      }
    },
    /** 修改标题 确定 */
    async handleConfirm(row) {
      const res = await this.axios.post('/dq_admin/product/editAuctionProduct', {
        id: row.id,
        productName: this.selectProductName
      })
      if (res.status !== '200') return
      this.$message.success('修改成功')
      this.data = await overwriteModifiedAuctionData(row.id, this.data)
    },
    /** 关闭 */
    handleClose(row) {
      this.data.forEach((el, index) => {
        if (el.id === row.id) {
          this.$set(this.data[index], 'showDoubleInput',  false)
        } else {
          this.data[index].showDoubleInput = false
        }
      })
    },
    /** 双击 */
    handleDoubleClick(id, name) {
      this.selectProductName = name
      this.data.forEach((el, index) => {
        if (el.id === id) {
          this.$set(this.data[index], 'showDoubleInput',  true)
        } else {
          this.data[index].showDoubleInput = false
        }
      })
    },
    /** 瑕疵编辑成功 */
    async handleEditorSuccess(data) {
      await this.getData()
    },
    /** 删除瑕疵描述 */
    async handleEditorProductRemark(row) {
      this.$refs.editorProductRemarkPopupEl.show({
        id: row.id,
        ...row.productRemarkVo
      })
    },
    /** 拖拽图片后 */
    handleDragImg(list) {
      this.fileList = list
    },
    /** 拖拽图片后 */
    handleDragYuanImg(list) {
      this.fileListYuan = list
    },
    // 搜索截拍日期筛选
    searchPickerChange(array) {
      if (array && array.length > 0) {
        array.forEach((ele, index) => {
          if (index == 0) {
            this.searchParams.queryStartEndTime = ele._d
                .toLocaleDateString()
                .replace(/\//g, "-");
          } else {
            this.searchParams.queryEndTime = ele._d
                .toLocaleDateString()
                .replace(/\//g, "-");
          }
        });
      } else {
        this.searchParams.queryStartEndTime = "";
        this.searchParams.queryEndTime = "";
      }
    },

    // 审核日期筛选
    searchReviewTimePickerChange(array) {
      if (array && array.length > 0) {
        array.forEach((ele, index) => {
          if (index == 0) {
            this.searchParams.reviewStartTime = ele._d
                .toLocaleDateString()
                .replace(/\//g, "-");
          } else {
            this.searchParams.reviewEndTime = ele._d
                .toLocaleDateString()
                .replace(/\//g, "-");
          }
        });
      } else {
        this.searchParams.reviewStartTime = "";
        this.searchParams.reviewEndTime = "";
      }
    },

    // 删除裁剪封面图
    async deleteImage(row) {
      const res = await this.axios("/dq_admin/product/cancelCutCover", {
        params: {
          productId: row.id
        }
      });
      if (res.status != 200) return
      this.$message.success(res.message);
      this.data = await overwriteModifiedAuctionData(row.id, this.data)
    },

    // 删除评级官网图
    async deleteOfficialCoverImage(row) {
      const res = await this.axios("/dq_admin/product/deleteOfficialCoverImage", {
        params: {
          productId: row.id
        }
      });
      if (res.status != 200) return
      this.$message.success(res.message);
      this.data = await overwriteModifiedAuctionData(row.id, this.data)
    },


    appreciate(row) {
      this.detail = JSON.parse(JSON.stringify(row));
      this.$refs["add-appreciate"].show = true;
    },

    async openModal(row, type) {
      this.imgKey = Math.random();
      this.fileList = [];
      this.fileListYuan = []
      if (type) {
        this.IO = 1;
        this.forms = JSON.parse(JSON.stringify(row));
        const {coinSid,coinKindName} = this.forms
        if(coinSid){
          this.currency_big_list = [{id:1,sid:coinSid,coinKindName}]
        }
        const { cutCoverUrls, coverUrls } = row || { cutCoverUrls: [], coverUrls: [] };
        setTimeout(() => {
          if (cutCoverUrls && cutCoverUrls.length > 0) {
            this.fileList = cutCoverUrls.map((items) => {
              const obj = {
                uid: utils.randomString(5),
                name: "image",
                status: "done",
                url: items,
              };
              return obj;
            });
          }
          if (coverUrls && coverUrls.length > 0) {
            this.fileListYuan = coverUrls.map((items) => {
              const obj = {
                uid: utils.randomString(6),
                name: "image",
                status: "done",
                url: items,
              };
              return obj;
            });
          }
        })
      } else {
        this.IO = 0;
        this.forms = {
          tags: [],
          coverUrls: [],
        };
      }
      this.visible = true;
      this.headerPriceListData = await getAuctionHeartPriceListEvent()
    },
    /** 修改心理价幅度 */
    handleChangeExpectPrice(e) {
      this.forms.incrPrice = this.headerPriceListData.dataList[e]
    },
    copy_text(text) {
      let oInput = document.createElement("input");
      oInput.value = text;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.remove();
      this.$message.success("已复制进剪切板");
    },
    copy(text) {
      this.copy_text(text);
    },
    hideModal() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.submit(this.forms);
        } else {
          return false;
        }
      });
    },
    resetForms() {
      this.forms = {};
      this.$refs.ruleForm.resetFields();
    },
    async getKinds() {
      const res = await this.axios("/dq_admin/kind/list");
    },
    submit(forms) {
      const postData = JSON.parse(JSON.stringify(forms));
      const images = this.fileList.map((row) => row.url);
      const imagesYuan = this.fileListYuan.map((row) => row.url);
      postData.cutCoverUrls = images;
      postData.coverUrls = imagesYuan
      const post_url = this.IO
          ? "/dq_admin/product/editAuctionProduct"
          : "/dq_admin/product/addProduct";
      this.axios.post(post_url, { ...postData }).then((res) => {
        if (res.status == "200") {
          this.$message.success(res.message);
          this.visible = false;
          this.getData();
        }
      });
    },
    async getData() {
      this.$loading.show()
      this.searchParams.deleteFlag = this.searchParams.deleteFlag ? 1 : 0
      this.loading = true;
      const res = await this.axios("/dq_admin/product/auctionGoodsList", {
        params: {
          ...this.params,
          ...this.searchParams,
        },
      });
      this.$loading.hide()
      this.loading = false;
      const { records, total } = res.data;
      if (records && records.length) {
        records.forEach(el => {
          el.showDoubleInput = false
        })
      }
      this.data = records;
      this.pagination.total = total || 0;
    },
    async getUsers() {
      const res = await this.axios("/dq_admin/user/list");
      const { data } = res;
      this.users = data;
    },
    search() {
      const result = this.times.map((row) => {
        const time = new Date(row._d).toLocaleDateString().replace(/\//g, "-");
        return time;
      });
      if (result && result.length > 0) {
        [this.searchParams.startTime, this.searchParams.endTime] = [...result];
      }
      this.params.pageNum = 1;
      this.pagination.current = 1
      this.searchParams.notEndTimeStatus = this.nullEndTimeCheck ? 1 : 0
      this.getData();
    },
    userIdSearch(id) {
      this.params.pageNum = 1;
      this.pagination.current = 1
      this.searchParams.userName = id;
      this.getData();
    },
    async remove(n) {
      const res = await this.axios.post("/dq_admin/product/delProduct", {
        productNo: n,
      });
      if (res.status == "200") {
        this.$message.success(res.message);
        this.getData();
      }
    },

    changePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getData();
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      fileList.map((row) => {
        try {
          if (row.response.data) {
            row.url = row.response.data;
          }
        } catch (e) {}
      });
      this.fileList = fileList;
    },
    searchData(inner) {
      this.axios("/dq_admin/kind/list", {
        params: { coinName: inner },
      }).then((res) => {
        if (res.status == "200") {
          const { records } = res.data;
          this.currency_big_list = records;
        }
      });
    },
    changeTime(time) {
      const { _d } = time;
      // this.forms.createTime = new Date(_d)
      //   .toLocaleTimeString()
      //   .replace(/\//g, "-");

      var dayStr = new Date(_d).toLocaleDateString().replace(/\//g, "-");

      var hourMinStr = new Date(_d).toLocaleTimeString().replace(/\//g, "-");

      this.forms.createTime = dayStr + " " + hourMinStr;
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(function () {
        this.$refs.input.focus();
      });
    },
    handleInputChange(e) {
      this.inputValue = e.target.value;
    },

    handleInputConfirm() {
      const inputValue = this.inputValue;
      const tags = this.forms.tags;
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags.push(inputValue);
      }
      Object.assign(this, {
        inputVisible: false,
        inputValue: "",
      });
    },
    previewImg(urls) {
      if (urls && urls.length > 0) {
        this.$previewImg({
          list: urls.reduce((pre, cur) => {
            const obj = { img_url: cur };
            pre = pre.concat(obj);
            return pre;
          }, []),

          baseImgField: "img_url",
          showMute: false,
        });
      }
    },
    paseImgs(type, data, row) {
      const len = this.fileList.length + 1;
      const obj = { uid: len, name: "image", status: "done", url: data };
      this.fileList.push(obj);
    },

    async reviewAuctionGood(id, type, refuseMark) {
      if (this.isloadingStatus) return this.$message.warn('正在修改，请稍后')
      this.isloadingStatus = true
      this.$loading.show()
      const res = await this.axios.post("/dq_admin/product/reviewAuctionGood", {
        productId: id,
        operType: type,
        secondRejectionStatus: 0,
        productRemark: refuseMark,
      });
      this.$loading.hide()
      this.isloadingStatus = false
      if (res.status === '200') {
        this.$message.success(res.message);
        this.data = await overwriteModifiedAuctionData(id, this.data, true)
        this.refuseModal = false;
        this.selectProductId = null;
      }
      if (this.sourceType === 'report') {
        const channel = new BroadcastChannel('my-channel');
        channel.postMessage(true)
      }
    },

    async cutAndRatingData(row) {
      this.$loading.show()
      const res = await this.axios("/dq_admin/product/cutRatingProduct", {
        params: {
          productId: row.id
        }
      });
      this.$loading.hide()
      this.data = await overwriteModifiedAuctionData(row.id, this.data)
      this.$message.success(res.message);
    },

    downloadCode(row) {
      const { productNo } = row;
      this.axios("/dq_admin/product/syntheticImg", {
        params: { productNo },
      }).then((res) => {
        const { status, data, message } = res;
        if (status == "200") {
          const alink = document.createElement("a");
          alink.href = data + "?response-content-type=application/octet-stream";
          alink.download = `${productNo}海报`;
          alink.click();
          alink.remove();
          this.$message.success(message);
        }
      });
    },

    // 竞拍记录
    changePageModal(page) {
      const { current } = page;
      this.paginationModal.current = current;
      this.modalParams.pageNum = current;
      this.getTakeMessages(this.modalParams);
    },
    openPriceModal(productId) {
      this.visiblePrice = true;
      Object.assign(this.modalParams, {
        pageNum: 1,
        pageSize: 10,
        productId: productId,
      });
      this.getTakeMessages(this.modalParams);
    },

    //驳回框 操作
    openRefuseModal(row) {
      this.selectProductId = row.id;
      this.refuseModal = true;
    },

    closeRefuseModal() {
      this.selectProductId = null;
      // this.productRefuseInput = "";
    },

    async confirmRefuseModal() {
      let found
      if (!this.showSelectRefuseAuto) {
        if (!this.productRefuseType) {
          return this.$message.warn('请选择驳回原因')
        }
        found = this.productRefuseTypeList.find(el => el.id === this.productRefuseType).value
      } else {
        if (!this.showSelectRefuseText) {
          return this.$message.warn('请输入驳回原因')
        }
        found = this.showSelectRefuseText
      }
      const res = await getAuctionLastTimeCheckStatusApi(this.selectProductId, found)
      if (res.status !== '200') return
      if (res.data) {
        this.$refs.rejectTipMessagePopupEl.show({
          productId: this.selectProductId,
          checkStatusMessage: res.message
        })
      } else {
        await this.reviewAuctionGood(this.selectProductId, 'refuse', found);
      }
    },

    cancelModal() {
      // this.modalParams = {}
      this.messages = [];
    },
    async getTakeMessages(params) {
      this.loadingModal = true;
      const result = await this.axios(
          "/dq_admin/productUserBid/getBidList",
          { params: params }
      );
      this.loadingModal = false;
      if (result.status === '200') {
        const { records, total } = result.data;
        this.messages = records;

        this.paginationModal.total = total;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-attr {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}
.height-column-sp-bt-center {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
::v-deep .gray {
  background-color: #f5f5f5 !important;
  color: rgba(0, 0, 0, 0.01) !important;
}
.delete-auction {
  filter:Alpha(Opacity=50);opacity:0.5 !important;
}
.tag-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.tag-item {
  padding: 5px 10px;
  height: 25px;
  border: 1px solid rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}
.tag-up-home {
  padding: 5px 10px;
  height: 25px;
  border: 1px solid rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  cursor: pointer;
}
.cur-tag-item {
  color: red !important;
  border: 1px solid red !important;
}
.images-box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.color-green {
  color: #008a00;
}
.color-blue {
  color: #13c2c2;
}
.ml-10 {
  margin-left: 5px;
}
.flex-colum-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.user-vip-box {
  padding: 5px 10px;
  height: 24px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: #FFA07A;
}
.vip-img {
  width: 29px;
  height: 16px;
}
</style>