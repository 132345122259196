var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          columns: _vm.tableList,
          "data-source": _vm.list,
          pagination: _vm.pagination,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemUserInfoSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", [
                  _vm._v(_vm._s(row.userName) + " "),
                  _c("span", { staticClass: "color-green" }, [
                    _vm._v("【" + _vm._s(row.userId) + "】")
                  ])
                ]),
                _c("div", { staticClass: "color-gray mt-10" }, [
                  _vm._v("点赞数："),
                  _c("span", { staticClass: "color-black font-weight-bold" }, [
                    _vm._v(_vm._s(row.likeCount))
                  ])
                ])
              ])
            }
          },
          {
            key: "itemStatusOrTypeSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { class: _vm.commentTypeClassType[row.type] }, [
                  _vm._v(_vm._s(_vm.computedCommentTypeListMapText(row.type)))
                ]),
                _c(
                  "div",
                  {
                    staticClass: "mt-10",
                    class: _vm.commentStatusClassType.get(row.state)
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.computedCommentStatusTypeListMapText(row.state)
                      )
                    )
                  ]
                )
              ])
            }
          },
          {
            key: "itemCommentDetailSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { staticClass: "font-weight-bold font-size-14" }, [
                  _vm._v("评论的活谱版别："),
                  _c("span", { staticClass: "color-dq-blue" }, [
                    _vm._v(_vm._s(row.kindItemVersionFullName))
                  ])
                ]),
                8 === Number(row.type)
                  ? _c("div", [
                      _c("div", { staticClass: "color-gray mt-10" }, [
                        _vm._v("评论内容："),
                        _c("span", { staticClass: "color-black" }, [
                          _vm._v(_vm._s(row.description))
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "flex-center-center img-list-comment" },
                        [
                          _c("img", {
                            attrs: { src: row.tukuCommentRealImage, alt: "" },
                            on: {
                              click: function($event) {
                                return _vm.handlePreviewImg(
                                  [
                                    row.tukuCommentRealImage,
                                    row.tukuCommentRealBackImage
                                  ],
                                  0
                                )
                              }
                            }
                          }),
                          _c("img", {
                            attrs: {
                              src: row.tukuCommentRealBackImage,
                              alt: ""
                            },
                            on: {
                              click: function($event) {
                                return _vm.handlePreviewImg(
                                  [
                                    row.tukuCommentRealImage,
                                    row.tukuCommentRealBackImage
                                  ],
                                  1
                                )
                              }
                            }
                          })
                        ]
                      )
                    ])
                  : _vm._e(),
                6 === Number(row.type)
                  ? _c("div", [
                      _c("div", { staticClass: "color-gray mt-10" }, [
                        _vm._v("评论内容："),
                        _c("span", { staticClass: "color-black" }, [
                          _vm._v(_vm._s(row.commentContent))
                        ])
                      ])
                    ])
                  : _vm._e()
              ])
            }
          },
          {
            key: "itemImgListSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                _vm._l(row.imgList, function(item, index) {
                  return _c("img", {
                    key: index,
                    attrs: { src: item, alt: "" },
                    on: {
                      click: function($event) {
                        return _vm.handlePreviewImg(row.imgList, index)
                      }
                    }
                  })
                }),
                0
              )
            }
          },
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  row.state === 0
                    ? _c(
                        "div",
                        { staticClass: "flex-center-center" },
                        [
                          _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                title: "确定通过此评论?",
                                "ok-text": "Yes",
                                "cancel-text": "No"
                              },
                              on: {
                                confirm: function($event) {
                                  return _vm.handleConfirmOrRejectComment(
                                    row,
                                    1
                                  )
                                }
                              }
                            },
                            [
                              _c("a-button", { attrs: { type: "primary" } }, [
                                _vm._v("通过")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "a-popconfirm",
                            {
                              attrs: {
                                title: "确定将此评论驳回?",
                                "ok-text": "Yes",
                                "cancel-text": "No"
                              },
                              on: {
                                confirm: function($event) {
                                  return _vm.handleConfirmOrRejectComment(
                                    row,
                                    -1
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  staticClass: "ml-20",
                                  attrs: { type: "danger" }
                                },
                                [_vm._v("驳回")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        title: "确定同步?",
                        "ok-text": "Yes",
                        "cancel-text": "No"
                      },
                      on: {
                        confirm: function($event) {
                          return _vm.handleAgainToVersionByUserComment(row)
                        }
                      }
                    },
                    [
                      row.type === "8"
                        ? _c(
                            "a-button",
                            {
                              staticClass: "mt-10",
                              attrs: { type: "primary" }
                            },
                            [_vm._v("用户图片同步到版别")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        title: "是否确认删除?",
                        "ok-text": "Yes",
                        "cancel-text": "No"
                      },
                      on: {
                        confirm: function($event) {
                          return _vm.handleDeleteImgStore(row)
                        }
                      }
                    },
                    [
                      _c(
                        "a-button",
                        { staticClass: "mt-10", attrs: { type: "danger" } },
                        [_vm._v("删除")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }